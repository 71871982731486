import * as React from 'react';
//import AttributeGrid from '../components/AttributeGrid';
import Container from '../components/Container';
import Hero from '../components/Hero';
//import BlogPreviewGrid from '../components/BlogPreviewGrid';
import Highlight from '../components/Highlight';
import Layout from '../components/Layout/Layout';
//import ProductCollectionGrid from '../components/ProductCollectionGrid';
//import ProductCardGrid from '../components/ProductCardGrid';
import Quote from '../components/Quote';
//import Title from '../components/Title';
//import Contact from '../components/Contact/Contact';
import Button from '../components/Button/Button';
import Slider from '../components/Slider/Slider';

//import { generateMockBlogData, generateMockProductData } from '../helpers/mock';

import * as styles from './index.module.css';
//import { Link, navigate } from 'gatsby';
//import FormInputField from '../components/FormInputField/FormInputField';
//import ContactForm from '../components/ContactForm/ContactForm';

const IndexPage = () => {
 // const newArrivals = generateMockProductData(3, 'shirt');
  //const blogData = generateMockBlogData(3);

 /* const goToShop = () => {
    navigate('/shop');
  };
*/
  return (
    <Layout disablePaddingBottom>
      {/* Hero Container */}
      <Hero
        maxWidth={'500px'}
        image={'https://framerusercontent.com/images/rit95avwg0ovLalK4Ed3Lz6bCdc.png'}
        title={'Experience the New Reality of Design'}
       // subtitle={'WebXR AR VR'}
        //ctaText={'shop now'}
        //ctaAction={goToShop}
     
      />

      {/* Slider importing from slider component
        below images are imported from assets folder and passed as props to slider component
       
       <Container size={'large'} fullMobile>
      <Slider>
        <img src={`https://framerusercontent.com/images/rit95avwg0ovLalK4Ed3Lz6bCdc.png`} alt={'slider 1'} />
        <img src={`https://live.staticflickr.com/65535/52812947917_f104c9acab_h.jpg`} alt={'slider 2'} />
        <img src={`https://live.staticflickr.com/65535/52870581779_ccc8526f0f_b.jpg`} alt={'slider 3'} />
        <img src={`https://live.staticflickr.com/65535/52870590288_13080f23a4_b.jpg`} alt={'slider 4'} />
        </Slider>
        </Container>*/}
      {/* Quote */}
      <Quote
        bgColor={'var(--blue-gradient)'}
        //title={'small studio | massive personality'}
        quote={
          'Philly based studio that focused on building XR solutions and creating memorable customer experiences'
        }
      />


      {/* Highlight  */}
      <div className={styles.highlightContainer}>
        <Container size={'large'} fullMobile>
          <Highlight
            image={'https://live.staticflickr.com/65535/52934450769_f85b6b3785_z.jpg'}
            altImage={'https://live.staticflickr.com/65535/52934450769_f85b6b3785_z.jpg'}
            title={'small studio | massive personality'}
            description={`We are always looking for new projects and opportunities. If you have an idea or a project you would like to discuss, please reach out.`}
            textLink={'contact@ardd.tech'}
          />
        </Container>
      </div>





     {/* {/* Social Media *
      <div className={styles.socialContainer}>
       
        <div className={styles.socialContentGrid}>
          <img src={`../image-assets/image-14.png`} alt={'social media 1'} />
          <img src={`https://live.staticflickr.com/65535/52870153536_656e7aa6d6_b.jpg`} alt={'social media 2'} />
          <img src={`https://live.staticflickr.com/65535/52870581779_ccc8526f0f_b.jpg`} alt={'social media 3'} />
          <img src={`https://live.staticflickr.com/65535/52870590288_13080f23a4_b.jpg`} alt={'social media 4'} />
        </div>
      </div>
*/}
   
    </Layout>
  );
};

export default IndexPage;
