//import { Link } from 'gatsby';
import React from 'react';
import * as styles from './Highlight.module.css';

const Highlight = (props) => {
  const {
    image,
    altImage,
    title,
    description,
    textLink
  } = props;

  return (
    <div className={styles.root}>
      <img alt={altImage} src={image} className={styles.highlightImage} />
      <div className={styles.contentContainer}>
        <h3>{title}</h3>
        <p>{description}</p>
        <a href='mailto:contact@ardd.tech' target='#'><h3>{textLink}</h3></a>
      </div>
    </div>
  );
};

export default Highlight;
